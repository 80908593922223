import React from 'react';
import { connect } from 'react-redux';
import { Button, PopEditor, SearchInput, DataTable, Pager } from 'tyb';
import queryString from 'query-string';
import enums from '@/assets/enum.json';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
import { formatDate } from 'ucode-utils';


const { enterpriseTimeGet } = iceStarkData.store.get('commonAction')?.enterprises;

const { notification } = iceStarkData.store.get('commonAction')?.ui;
import ViewDialog from './ViewDialog';
import { ConfirmPopout } from 'ucode-components';
import { handSortOrder } from 'ucode-utils';
import '../allot.scss';
interface State {
    // filterPrizeStatus: (string | number)[];
    // params: paramsType;
    // visible: string;
    // record?: ReceiverContent
}
interface Props {
    // loading: boolean;
    // permissionIds: Array<string>;
    // presentsPoolsList?: (string | number | object)[];
    // presentsPoolsList: {
    //   content?: contentType[];
    //   totalElements?: number;
    //   [propName: string]: any
    // }; 
    // // pointMallSelectionsList: Array<any>; // 未使用
    // getPresentsPoolsList: typeof presentsPools.REQUEST;
    // history: historyType
}


/**
 * 小程序模版 -页面装修
 */

@(connect(
    (state: IStore) => ({

        permissionIds: state.users.permissionIds || [],

        loading: state.enterprises.loadTimeConfigListLoading,

        enterpriseTimeList: state.enterprises.enterpriseTimeList,

    }),
    {
        enterpriseTimeGet: enterpriseTimeGet.REQUEST,

        notification,
        setNavBar
    }
) as any)

export default class TimeConfig extends React.Component<Props, State> {
    readonly state: State
    constructor(props: Props) {
        super(props);
        this.state = {
            page: 0,
            perPageCount: 20,

            viewObject: {},
            viewDialogStatus: false,

        };
        this.params = { page: 0, size: 20 };
        this.columns = [
            {
                key: "expirationTime",
                dataIndex: "expirationTime",
                title: "过期时间",
                render: (value, record) => <div>{value ? formatDate(value) : ""}</div>,
            },
            {
                key: "createdTime",
                dataIndex: "createdTime",
                title: "设置时间",
                render: (value, record) => <div>{value ? formatDate(value) : ""}</div>,
            },
            {
                key: 'remark',
                dataIndex: 'remark',
                title: '变更原因',
            },


        ]
    }

    componentDidMount() {
        const { location } = this.props;
        const parsed = queryString.parse(location.search);
        const { id } = parsed;
        this.eseId = id;
        this.params.eseId = id;
        this.props.enterpriseTimeGet(this.params);
    }


    search = () => {
        this.setState({
            page: 0,
        });
        this.params.page = 0;
        this.props.enterpriseTimeGet(this.params);
    }

    // 切换页数
    onPageChange = (val) => {
        this.setState({ page: val });
        this.params.page = val;
        this.props.enterpriseTimeGet(this.params);
    }

    // 切换每页数量
    onPerPageChange = (val) => {
        this.setState({ perPageCount: val });
        this.params.size = val;
        this.params.page = 0;
        this.props.enterpriseTimeGet(this.params);
    }

    handleOperate = (type, record, flag) => {
        switch (type) {
            case 'add': // 添加
                this.showDialog(9, record)
                break;
        }
    }


    getColumns = () => {
        return this.columns.map(column => {
            // if (column.key == 'expirationTime') {
            //     return {
            //         ...column,
            //         headerRender: this.renderSortHead
            //     }
            // } else if (column.key == 'createdTime') {
            //     return {
            //         ...column,
            //         headerRender: this.renderSortHead2
            //     }
            // } else {
                return column
            // }
        })
    }


    renderSortHead = (col) => {
        return (
            <DataTable.SortableColumnHeader
                order={this.state[`${col.key}Sort`]}
                sortKey={col.key}
                onSort={() => { this.handleSort(col.key, this.state[`${col.key}Sort`]) }}
            >{col.title}</DataTable.SortableColumnHeader>
        )
    }
    handleSort = (key, order) => {
        const newOrder = handSortOrder(order);

        this.props.enterpriseTimeGet({ ...this.params, sort: `${key},${newOrder}` });
        this.params.sort = `${key},${newOrder}`
        this.setState({ expirationTimeSort: newOrder });
        if (this.state.createdTimeSort !== null) {
            this.setState({ createdTimeSort: null });
        }
    }

    renderSortHead2 = (col) => {
        return (
            <DataTable.SortableColumnHeader
                order={this.state[`${col.key}Sort`]}
                sortKey={col.key}
                onSort={() => { this.handleSort2(col.key, this.state[`${col.key}Sort`]) }}
            >{col.title}</DataTable.SortableColumnHeader>
        )
    }
    handleSort2 = (key, order) => {
        const newOrder = handSortOrder(order);

        this.props.enterpriseTimeGet({ ...this.params, sort: `${key},${newOrder}` });
        this.params.sort = `${key},${newOrder}`
        this.setState({ createdTimeSort: newOrder });
        if (this.state.expirationTimeSort !== null) {
            this.setState({ expirationTimeSort: null });
        }

    }



    showDialog = (index, item) => {
        this.setState({ viewObject: item || {}, viewDialogStatus: index })
    }


    handleCancel = () => {
        this.setState({
            viewDialogStatus: false,
            viewObject: {},
        })
    }






    render() {
        const { loading, enterpriseTimeList = {}, permissionIds } = this.props;
        const { content, totalElements = 0 } = enterpriseTimeList;
        const {  page, perPageCount, viewDialogStatus } = this.state;

        return (
            <React.Fragment>
                <div className="timeConfig">
                    <div className="search-bar">
                        {permissionIds.includes('enterprise.expirationTimeRecord') &&
                            <Button onClick={() => { this.handleOperate('add') }}>企业过期设置</Button>
                        }
                        <div className="search-input-block">
                            {/* <SearchInput
                                onChange={(val) => { this.params.keywords = val; this.setState({ keywords: val }) }}
                                onSearch={this.search}
                                value={keywords}
                                placeholder="输入排行名称或编码查询"
                            /> */}
                        </div>
                    </div>
                    <DataTable
                        columns={this.getColumns()}
                        data={content}
                        rowKey="id"
                        loading={loading}
                        rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
                        messageRender={({ data, error }) => {
                            if (error != null) {
                                return error.msg
                            }
                            if (data == null || data.length === 0) {
                                return <span className="empty">暂无过期设置{permissionIds.includes('enterprise.expirationTimeRecord') && <React.Fragment>，您可以点击左上角 <a onClick={() => { this.handleOperate('add') }}> 企业过期设置</a></React.Fragment>}</span>
                            }
                        }}
                    />
                    {content && content.length > 0 && <Pager
                        total={totalElements}
                        current={page}
                        perPageCountList={[20, 50, 100]}
                        perPageCount={perPageCount}
                        onPageChange={this.onPageChange}
                        onPerPageChange={this.onPerPageChange}
                    />}

                </div>
                {viewDialogStatus && <ViewDialog visible={viewDialogStatus} viewObject={this.state.viewObject} params={this.params} handleCancel={this.handleCancel} />}


            </React.Fragment>
        )
    }




}