import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Button, DataTable, Pager, PopEditor } from 'tyb';
import CreatePoolPrize from './components/CreatePoolPrize';
import queryString from 'query-string';
const { poolPageByEseId, poolPrizeNotNeedStatistics } = iceStarkData.store.get('commonAction')?.pools;

import './index.scss';

/* 预警配置 */
@withRouter
@connect(
    state => ({
        permissionIds: state.users.permissionIds||[],
        loading: state.pools.loading,
        poolPageByEseId: state.pools.poolPageByEseId,
        poolPrizeNotNeedStatisticsLoaded: state.pools.poolPrizeNotNeedStatisticsLoaded,
    }),
    {
        getPoolPageByEseId: poolPageByEseId.REQUEST,
        setPoolPrizeNotNeedStatistics: poolPrizeNotNeedStatistics.REQUEST,
    })
export default class NoStatisticsconfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            perPageCount: 20,
        };
        this.params = { page: 0, size: 20 };
        this.columns = [
            {
                key: 'poolId',
                dataIndex: 'poolId',
                title: '奖池ID',
            },
            {
                key: 'poolName',
                dataIndex: 'poolName',
                title: '奖池名称',
            },
            {
                key: 'poolPrizeId',
                dataIndex: 'poolPrizeId',
                title: '奖池下奖品ID',
            },
            {
                key: 'prizeId',
                dataIndex: 'prizeId',
                title: '奖品ID',
            },
            {
                key: 'prizeName',
                dataIndex: 'prizeName',
                title: '奖品名称',
            },
            {
                key: 'operate',
                dataIndex: 'operate',
                title: '操作',
                render: (val, record, index) => (
                    // {this.props.permissionIds.includes('data.we.urlConfig') && 
                    <React.Fragment>
                        <div className="table-operate-btn">
                            <a className="operate" href="javascript:;" onClick={() => { this.setState({ _visible: `${index}_visible` }); }}>删除</a>
                        </div>
                        {this.state._visible == `${index}_visible` &&
                            <div className="confirm-popEditor">
                                <PopEditor
                                    onOk={() => { this.handleDelete(record); this.setState({ _visible: '' }); }}
                                    onCancel={() => { this.setState({ _visible: '' }); }}
                                >
                                    <p>
                                        <strong>是否删除？</strong>
                                        <br></br>
                                        确认删除此设置吗
                                    </p>
                                </PopEditor>
                            </div>
                        }
                    </React.Fragment>
                )
            },
        ]
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        const { id, name } = parsed;
        this.params.eseId = id;
        this.props.getPoolPageByEseId(this.params);
        this.setState({ eseId: id, name });
    }

    componentDidUpdate(preProps) {
        if (/2\d{2}$/.test(this.props.poolPrizeNotNeedStatisticsLoaded) && this.props.poolPrizeNotNeedStatisticsLoaded !== preProps.poolPrizeNotNeedStatisticsLoaded) {
            this.setState({ visible: false });
            this.props.getPoolPageByEseId(this.params);
        }
    }

    // 切换页数
    onPageChange = (val) => {
        this.setState({ page: val });
        this.params.page = val;
        this.props.getPoolPageByEseId(this.params);
    }

    // 切换每页数量
    onPerPageChange = (val) => {
        this.setState({ perPageCount: val });
        this.params.size = val;
        this.params.page = 0;
        this.props.getPoolPageByEseId(this.params);
    }

    handleVisible = (refresh) => {
        this.setState({ visible: !this.state.visible });
        if (refresh) {
            this.props.getPoolPageByEseId(this.params);
        }
    }

    // 删除
    handleDelete = (data = {}) => {
        this.props.setPoolPrizeNotNeedStatistics({ poolPrizeId: data.poolPrizeId, notNeedStatistics: false });
    }

    render() {
        const { loading, poolPageByEseId = {}, permissionIds } = this.props;
        const { content, totalElements = 0 } = poolPageByEseId;
        const { page, perPageCount, visible, eseId, name } = this.state;
        return (
            <div className="NoStatisticsconfig">
                <div className="hint">设置不需要参与大数据报表统计的奖品</div>
                <div className="name">{name}</div>
                <div className="search-bar">
                    <Button onClick={() => { this.handleVisible() }}>新增奖池奖品</Button>
                </div>
                <DataTable
                    columns={this.columns}
                    data={content}
                    rowKey="id"
                    loading={loading}
                />

                <Pager
                    total={totalElements}
                    current={page}
                    perPageCountList={[20, 50, 100]}
                    perPageCount={perPageCount}
                    onPageChange={this.onPageChange}
                    onPerPageChange={this.onPerPageChange}
                />

                <CreatePoolPrize
                    visible={visible}
                    eseId={eseId}
                    handleVisible={this.handleVisible}
                />
            </div>
        )
    }
}


