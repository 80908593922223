import React from 'react';
import { connect } from 'react-redux';
import { TabList } from 'tyb';
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
const { enterpriseinfo } = iceStarkData.store.get('commonAction')?.enterpriseinfo;

import WaitConfirm from './components/WaitConfirm';
import AlreadyPassed from './components/AlreadyPassed';
import './allot.scss';

@withRouter
@connect(
  state => ({
    enterpriseinfoinfo: state.enterpriseinfo.enterpriseinfoinfo,

  }),
  {
    enterpriseinfo: enterpriseinfo.REQUEST,
  }
)
class CodeTempConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabList: [
        {
          value: 'waitconfirm',
          text: '码量'
        },
        {
          value: 'alreadypassed',
          text: '域名'
        },
      ],
      nowTab: 'waitconfirm',
    }
  }
  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    const { id } = parsed;
    this.props.enterpriseinfo(id)

  }

  changeTab = (value) => {
    this.setState({
      nowTab: value
    })

  }




  render() {
    const { tabList, nowTab } = this.state;
    const parsed = queryString.parse(this.props.location.search);
    const { name } = parsed;
    return (


      <div className="allotCodeConfiguration">
        <div className='title'>{name}</div>
        <div className='table'>
          <div style={{ width: '25%',display:'inline-block' }}>
            <b>分配总码量</b>
            <br></br>
            <span style={{fontSize: '28px'}}>{this.props.enterpriseinfoinfo?this.props.enterpriseinfoinfo.upCount+this.props.enterpriseinfoinfo.createdCount:'0'}</span>
          </div>
          <div style={{ width: '25%',display:'inline-block', borderLeft: ' 1px solid #DDDDDD',paddingLeft:10 }}>
          <b>生成平台码量</b>
            <br></br>
            <span style={{fontSize: '28px'}}>{this.props.enterpriseinfoinfo?this.props.enterpriseinfoinfo.createdCount-this.props.enterpriseinfoinfo.thirdCodeCount:'0'}</span>
          </div>
          <div style={{ width: '25%',display:'inline-block', borderLeft: ' 1px solid #DDDDDD',paddingLeft:10  }}>
          <b>第三方映射码量</b>
            <br></br>
            <span style={{fontSize: '28px'}}>{this.props.enterpriseinfoinfo?this.props.enterpriseinfoinfo.thirdCodeCount?this.props.enterpriseinfoinfo.thirdCodeCount:'0':'0'}</span>
          </div>
          <div style={{ width: '25%',display:'inline-block', borderLeft: ' 1px solid #DDDDDD',paddingLeft:10  }}>
          <b>可用余量</b>
            <br></br>
            <span style={{fontSize: '28px'}}>{this.props.enterpriseinfoinfo?this.props.enterpriseinfoinfo.upCount:'0'}</span>
       
          </div>
        </div>

        <div className="main-block">
          <TabList
            list={tabList}
            value={nowTab}
            onChange={this.changeTab}
          >
          </TabList>

          <WaitConfirm isShow={nowTab === 'waitconfirm'} />
          <AlreadyPassed isShow={nowTab === 'alreadypassed'} />


        </div>

      </div>
    )
  }
}

export default CodeTempConfig;