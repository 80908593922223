import React from 'react';
import { connect } from 'react-redux';
import { DataTable, Pager, Button } from 'tyb'
import queryString from 'query-string';

const { loaddomainConfigsList, ableDomainConfigs } = iceStarkData.store.get('commonAction')?.domainConfigs;

import '../allot.scss';
import { withRouter } from "react-router-dom";


import ViewDialog from './ViewDialog';

@withRouter
@connect(
  state => ({
    factorySelector: state.factory.factorySelector,
    selector: state.products.selector,


    domainConfigsList: state.domainConfigs.domainConfigsList,
    domainConfigsListLoading: state.batchInformation.domainConfigsListLoading,
    permissionIds: state.users.permissionIds||[],
  }),
  {
    loaddomainConfigsList: loaddomainConfigsList.REQUEST,
    ableDomainConfigs: ableDomainConfigs.REQUEST,

  }
)
class WaitConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      perPageCountList: [20, 50, 100],
      params: {
        idOrName: '',
        applyStart: '',
        applyEnd: '',
        factoryIds: '',
        page: 0,
        size: 20,
        sort: '',
      },
      viewDialogStatus: false,
      viewObject: {}
    }
  }

  componentDidMount() {
    const { loaddomainConfigsList } = this.props;
    const parsed = queryString.parse(this.props.location.search);
    const { id } = parsed;

    const params = this.state.params;
    params.eseId = id;
    this.setState({
      params
    },
      () => {
        loaddomainConfigsList(this.state.params);
      }
    );


  }

  handleAble = (record, type) => {
    if (type == 'disable') {


      this.props.ableDomainConfigs(
        {
          data: {
            id: record.id,
            status: 'DISABLE',
          },
          callback: () => {

            // this.handleCancel();
            this.props.loaddomainConfigsList(this.state.params)
          }
        }
      )


    }
    else if (type == 'enable') {
      this.props.ableDomainConfigs(
        {
          data: {
            id: record.id,
            status: 'ENABLE',
          },
          callback: () => {

            // this.handleCancel();
            this.props.loaddomainConfigsList(this.state.params)
          }
        }
      )

    }
  }



  getTabColumns = () => {
    const { permissionIds } = this.props;
    return [

      {
        key: 'name',
        dataIndex: 'name',
        title: '名称',
      },
      {
        key: 'domain',
        dataIndex: 'domain',
        title: '域名',
        render: (value, record) => {
          if (record.codeSourceType == 'WECHART_CODE') {
            return <div>
              <div title={value}><span className="text-overflow">{value}</span></div>
            </div>
          }
          else {
            return <div>
              <div title={`${record.protocol}${value}/${record.subDomain}`}><span className="text-overflow">{record.protocol}{value}/{record.subDomain}</span></div>
            </div>
          }
        }
      },
      {
        key: 'codeSourceType',
        dataIndex: 'codeSourceType',
        title: '域名类型',
        render: (value, record) => {
          let list = { CODE_IMPORT: '第三方码', PLATFORM_SHORT_CODE: '平台短码', PLATFORM_STANDARD: '平台标准', WECHART_CODE: "微信码", WRITE_OFF_CODE: '核销码' }
          return <div>
            <div title={list[value]}><span className="text-overflow">{list[value]}</span></div>
          </div>
        }
      },
      {
        key: 'op',
        dataIndex: 'op',
        title: '操作',
        render: (value, record) =>
          //  permissionIds.includes('code.registration.batch.revocation')&&
          <div className="table-operate-btn">
            {(record.status == 'ENABLE') && //&& permissionIds.includes('ws.tempConfig.edit')
              <span onClick={() => {
                this.handleAble(record, 'disable')
              }}>禁用</span>
            }
            {(record.status == 'DISABLE') &&//&& permissionIds.includes('ws.tempConfig.edit')
              <span onClick={() => {
                this.handleAble(record, 'enable')
              }}>启用</span>
            }

            <span onClick={() => {
              this.handleEdit(record)
            }}>编辑</span>
          </div>
      },
    ]
  }

  getColumns = () => {
    return this.getTabColumns().map(column => {
      return column;
    });
  }

  onPageChange = (val) => {
    const { params } = this.state;
    params.page = val;
    this.setState({ params });
    this.props.loaddomainConfigsList(params);
  }

  onPerPageChange = (val) => {
    const params = this.state.params;
    params.size = val;
    params.page = 0;
    this.setState({
      params
    });
    this.props.loaddomainConfigsList(params);
  }
  handleCreate = () => {
    const parsed = queryString.parse(this.props.location.search);
    const { id } = parsed;
    this.setState({
      viewDialogStatus: 6,
      viewObject: { id },
    })
  }
  handleEdit = (data) => {
    this.setState({
      viewDialogStatus: 7,
      viewObject: data,
    })
  }

  handleCancel = () => {
    this.setState({
      viewDialogStatus: false,
      viewObject: {},
    })
  }


  render() {
    const { params, perPageCountList, viewDialogStatus, viewObject } = this.state;
    const { isShow, noDateButton, domainConfigsList = {}, domainConfigsListLoading, selector, factorySelector } = this.props;
    const applyUnauditedsLength = domainConfigsList.content ? domainConfigsList.content.length : 0
    return (
      <div style={{ display: isShow ? 'unset' : 'none' }} >
        <Button onClick={this.handleCreate}>创建域名</Button>
        <div>
          {/* <SearchBar
            searchText={params.idOrName}
            search={this.search}
            clear={this.clear}
            dataRange={{ from: params.applyStart, to: params.applyEnd }}
            changeDataRange={this.changeDataRange}
            changeSearchBox={this.changeSearchBox}
        /> */}
          <DataTable
            data={domainConfigsList.content}
            columns={this.getColumns()}
            rowKey="id"
            rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
            // selection={{
            //   value: this.props.selections,
            //   // maxCount: 3,
            //   // mode: 'row',
            //   onChange: this.handleSelectionChange,
            //   onChangeAll: this.handleSelectionAllChange,
            //   // isDisabled: record => record.id >= 2

            // }}
            loading={domainConfigsListLoading}
            messageRender={({ data, error }) => {
              if (error != null) { return error.msg; }
              if (data == null || data.length === 0) {
                return <span className="text-weak">暂无数据</span>;
              }
            }}
          />
          {applyUnauditedsLength > 0 && !domainConfigsListLoading ? <div className="page-padding20">
            <Pager
              total={domainConfigsList.totalElements}
              current={params.page}
              perPageCountList={perPageCountList}
              perPageCount={params.size}
              onPageChange={this.onPageChange}
              onPerPageChange={this.onPerPageChange}
            />
          </div> : ''}
          {viewDialogStatus && <ViewDialog visible={viewDialogStatus} viewObject={viewObject} handleCancel={this.handleCancel} params={params} />}
        </div>
      </div>
    )
  }
}

export default WaitConfirm;