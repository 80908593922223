import React from 'react';
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { Input, FormGroupList, Dialog, Textarea, notification as Notification } from 'tyb';
const { singleCodeQueryToolTypeList, singleCodeQueryToolTypeUpdate } = iceStarkData.store.get('commonAction')?.singleCodeQueryToolType;
import { FormGroupField } from 'tyb';
import { trims, matchChineseRegExp } from 'ucode-utils';

import './AddAndEditDialog.scss'

interface IProps {
  visible: boolean,
  title: string,
  type: string,
  hideDialog,
  record,
  initialize,
  handleSubmit,
  change,
  updateSingleCodeQueryToolType,
  getSingleCodeQueryToolTypeList,
  requestLatestData,
  page,
  size,
  sort,
  work,
  eseId,
  content
}

interface IState {

}

export const required1 = (message) => (value) => {
  if (value && value.length > 50) {
    return '长度最大为50个汉字'
  }
  return (!value || (value + '').trim() === '' ? message : undefined);
}

export const required2 = (message) => (value) => {
  if (matchChineseRegExp.test(value)) {
    return '编码不能输入汉字'
  }
  if (value && value.length > 50) {
    return '长度最大为50个汉字'
  }
  return (!value || (value + '').trim() === '' ? message : undefined);
}

export const required3 = (message) => (value) => {
  if (value && value.length > 100) {
    return '长度最大为100个汉字'
  }
}

const validateField1 = required1('请输入');
const validateField2 = required2('请输入');
const validateField3 = required3('请输入');

class AddAndEditDialog0 extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { record, type, requestLatestData } = this.props;
    const { filedName, filedCode, remark } = record;

    type === 'edit' &&
      this.props.initialize({ filedName, filedCode, remark });
  }

  render() {
    const { visible, title, record, type, handleSubmit } = this.props;
    return (
      <Dialog
        className='single-code-query-tool-dialog'
        title={title}
        visible={visible}
        onOk={handleSubmit(this.onSubmit)}
        onCancel={() => { this.onCancel() }}
      >
        <FormGroupList>
          <FormGroupField
            className='field'
            name='filedName'
            label='条件名称'
            placeholder='请输入'
            component={Input}
            required
            validate={[validateField1]}
          />
          <FormGroupField
            className='field'
            name='filedCode'
            label='条件编码'
            placeholder='请输入'
            disabled={type === 'edit' && record.sysDef ? true : false}
            component={Input}
            required
            validate={[validateField2]}
          />
          <FormGroupField
            name="remark"
            label="备注"
            component={Textarea}
            style={{ width: '100%' }}
            validate={[validateField3]}
          ></FormGroupField>
        </FormGroupList>
      </Dialog>
    )
  }

  onSubmit: (value) => void = (value) => {
    const { type, record, requestLatestData, page, size, sort, work, eseId, content } = this.props;
    let method = '';
    if (content.find(item => item.filedName == value.filedName)) {
      Notification.error('条件名称重复，请重新设置');
      return;
    }
    if (type == 'add') {
      method = 'POST';
      this.props.updateSingleCodeQueryToolType(trims({
        ...value, method, type, eseId, requestLatestData: () => {
          this.props.getSingleCodeQueryToolTypeList({ page, size, sort, work, eseId, requestLatestData });
        }
      }))
    } else if (type == 'edit') {
      method = 'PUT';
      const { id, version } = record;

      this.props.updateSingleCodeQueryToolType(trims({
        ...value, id, version, method, type, eseId, requestLatestData: () => {
          this.props.getSingleCodeQueryToolTypeList({ page, size, sort, work, eseId, requestLatestData });
        }
      }))
    }
    this.props.hideDialog();
  }

  onCancel: () => void = () => {
    this.props.hideDialog();
  }

}

const AddAndEditDialog = reduxForm({
  form: 'AddAndEditDialogForm'
})(connect(
  (state: IStore) => ({

  }),
  {
    getSingleCodeQueryToolTypeList: singleCodeQueryToolTypeList.REQUEST,
    updateSingleCodeQueryToolType: singleCodeQueryToolTypeUpdate.REQUEST,
  }
)(AddAndEditDialog0));

export default AddAndEditDialog;
