import React from 'react';
import { connect } from 'react-redux';
import { DataTable, Pager, Button } from 'tyb'
import queryString from 'query-string';

const { loadenterpriseinfolist } = iceStarkData.store.get('commonAction')?.enterpriseinfo;
import { formatDate } from 'ucode-utils';
import '../allot.scss';
import { withRouter } from "react-router-dom";

const { loadCodedbSelect } = iceStarkData.store.get('commonAction')?.codedb

import ViewDialog from './ViewDialog';

@withRouter
@connect(
  state => ({
    factorySelector: state.factory.factorySelector,
    selector: state.products.selector,

    enterpriseinfoinfo: state.enterpriseinfo.enterpriseinfoinfo,
    enterpriseinfolist: state.enterpriseinfo.enterpriseinfolist,
    loadenterpriseinfolistLoading: state.enterpriseinfo.loadenterpriseinfolistLoading,
    permissionIds: state.users.permissionIds || [],
  }),
  {
    loadCodedbSelect: loadCodedbSelect.REQUEST,
    loadenterpriseinfolist: loadenterpriseinfolist.REQUEST,

  }
)
class WaitConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // tableColumns: this.getTabColumns(),
      perPageCountList: [20, 50, 100],
      params: {
        idOrName: '',
        applyStart: '',
        applyEnd: '',
        factoryIds: '',
        page: 0,
        size: 20,
        sort: '',
      },
      viewDialogStatus: false,
      viewObject: {}
    }
  }

  componentDidMount() {
    const { loadenterpriseinfolist, loadCodedbSelect } = this.props;
    const parsed = queryString.parse(this.props.location.search);
    const { id } = parsed;

    const params = this.state.params;
    params.eseId = id;
    this.setState({
      params
    },
      () => {
        loadenterpriseinfolist(this.state.params);
      }
    );


    loadCodedbSelect({});


  }





  getTabColumns = () => {
    const { permissionIds } = this.props;
    return [

      {
        key: 'count',
        dataIndex: 'count',
        title: '分配码量',
        render: (value, record) => <div>

          <div title={(record.adjustmentType == 'ADD' ? '+' : '-') + value}><span className="text-overflow">{(record.adjustmentType == 'ADD' ? '+' : '-') + value}</span></div>
        </div>
      },
      {
        key: 'ip',
        dataIndex: 'ip',
        title: '分配IP',
      },
      {
        key: 'createdName',
        dataIndex: 'createdName',
        title: '分配人',
      },
      {
        key: 'createdTime',
        dataIndex: 'createdTime',
        title: '分配时间',
        render: (value, record) => <div title={value ? formatDate(value) : ''}>
          <span className="text-overflow">{value ? formatDate(value) : ''}</span>
        </div>
      },
    ]
  }

  getColumns = () => {
    return this.getTabColumns().map(column => {
      return column;
    });
  }

  onPageChange = (val) => {
    const { params } = this.state;
    params.page = val;
    this.setState({ params });
    this.props.loadenterpriseinfolist(params);
  }

  onPerPageChange = (val) => {
    const params = this.state.params;
    params.size = val;
    params.page = 0;
    this.setState({
      params
    });
    this.props.loadenterpriseinfolist(params);
  }

  handleCreate = () => {
    const parsed = queryString.parse(this.props.location.search);
    const { id } = parsed;
    this.setState({
      viewDialogStatus: 5,
      viewObject: { id },
    })
  }
  handleCancel = () => {
    this.setState({
      viewDialogStatus: false,
      viewObject: {},
    })
  }

  handleCreate8 = () => {
    const parsed = queryString.parse(this.props.location.search);
    const { id } = parsed;
    let warningValue = this.props.enterpriseinfoinfo ? this.props.enterpriseinfoinfo.warningValue ? this.props.enterpriseinfoinfo.warningValue : this.props.enterpriseinfoinfo.warningValue == '0' ? 0 : '' : '';
    this.setState({
      viewDialogStatus: 8,
      viewObject: { id, warningValue},
    })
  }

  render() {
    const { params, perPageCountList, viewDialogStatus, viewObject } = this.state;
    const { isShow, enterpriseinfolist = {}, loadenterpriseinfolistLoading } = this.props;
    const applyUnauditedsLength = enterpriseinfolist.content ? enterpriseinfolist.content.length : 0
    return (
      <div style={{ display: isShow ? 'unset' : 'none' }} >
        <Button style={{ marginRight: 10 }} onClick={this.handleCreate}>分配码量</Button>
        {/* 迎驾贡注释 */}
        {/* <Button className='weak' onClick={this.handleCreate8}>低额预警设置</Button> */}
        <div>

          {/* <SearchBar
            searchText={params.idOrName}
            search={this.search}
            clear={this.clear}
            dataRange={{ from: params.applyStart, to: params.applyEnd }}
            changeDataRange={this.changeDataRange}
            changeSearchBox={this.changeSearchBox}
        /> */}
          <DataTable
            data={enterpriseinfolist.content}
            columns={this.getColumns()}
            rowKey="id"
            // selection={{
            //   value: this.props.selections,
            //   // maxCount: 3,
            //   // mode: 'row',
            //   onChange: this.handleSelectionChange,
            //   onChangeAll: this.handleSelectionAllChange,
            //   // isDisabled: record => record.id >= 2

            // }}
            loading={loadenterpriseinfolistLoading}
            messageRender={({ data, error }) => {
              if (error != null) { return error.msg; }
              if (data == null || data.length === 0) {
                return <span className="text-weak">暂无数据</span>;
              }
            }}
          />
          {applyUnauditedsLength > 0 && !loadenterpriseinfolistLoading ? <div className="page-padding20">
            <Pager
              total={enterpriseinfolist.totalElements}
              current={params.page}
              perPageCountList={perPageCountList}
              perPageCount={params.size}
              onPageChange={this.onPageChange}
              onPerPageChange={this.onPerPageChange}
            />
          </div> : ''}


          {viewDialogStatus && <ViewDialog visible={viewDialogStatus} viewObject={viewObject} handleCancel={this.handleCancel} params={params} />}
        </div>
      </div>
    )
  }
}

export default WaitConfirm;