import React from 'react';
import { connect } from 'react-redux';
import { Dialog, FormGroupList } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';
const { poolPrizeNotNeedStatistics, getPoolDataByEseId, } = iceStarkData.store.get('commonAction')?.pools;
import '../allot.scss';
import { NewSelect } from 'ucode-components';

/* 不统计配置--创建奖池奖品 */
const requiredPool = (val) => {
    if (!val) {
        return '请选择奖池';
    }
    return null;
}


const requiredPrize = (val) => {
    if (!val) {
        return '请选择奖品';
    }
    return null;
}

@connect(
    state => ({
        poolDataByEseId: state.pools.poolDataByEseId,
        prizeListUnderPool: state.pools.prizeListUnderPool,
    }),
    {
        getPoolDataByEseId: getPoolDataByEseId.REQUEST,
        setPoolPrizeNotNeedStatistics: poolPrizeNotNeedStatistics.REQUEST,
    }
)
class CreatePoolPrize extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prizeList: [],
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(preProps) {
        if (this.props.visible && this.props.visible !== preProps.visible) {
            this.props.initialize({});
            this.setState({ prizeList: [] });
        }
        if (this.props.eseId && this.props.eseId !== preProps.eseId) {
            this.props.getPoolDataByEseId({ eseId: this.props.eseId });
        }
    }

    handleChangePool = (data) => {
        this.setState({ prizeList: this.props.prizeListUnderPool[data] });
    }

    // 提交
    handleSubmit = (data) => {
        const { poolPrizeId } = data;
        this.props.setPoolPrizeNotNeedStatistics({ poolPrizeId, notNeedStatistics: true });
    }

    render() {
        const { visible, handleVisible, handleSubmit, poolDataByEseId = {}, confirming } = this.props;
        const { content } = poolDataByEseId;
        return (
            <Dialog
                className="CreatePoolPrize"
                title="新增奖池奖品"
                visible={visible}
                onCancel={() => { handleVisible() }}
                onOk={handleSubmit(this.handleSubmit)}
                confirming={confirming}
            >
                <div>
                    <FormGroupList>
                        <FormGroupField
                            name="poolId"
                            label="选择奖池"
                            component={NewSelect}
                            style={{width:330}}
                            list={content}
                            validate={[requiredPool]}
                            onChange={this.handleChangePool}
                            placeholder="选择奖池"
                        />
                        <FormGroupField
                            name="poolPrizeId"
                            label="选择奖品"
                            component={NewSelect}
                            style={{width:330}}
                            list={this.state.prizeList}
                            placeholder="选择奖品"
                            validate={[requiredPrize]}
                        />
                    </FormGroupList>
                </div>
            </Dialog>
        )
    }
}

export default reduxForm({
    form: 'CreatePoolPrizeForm',
    initialValues: {

    }
})(CreatePoolPrize)

