import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form'
import { FormGroupList, Input, Button, RadioGroup, Checkbox, DateTimePicker, Dialog } from 'tyb';
import { NewSelect } from 'ucode-components';
import { MultipleSelect } from 'ucode-components';
import { FormGroupField } from 'tyb'
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
const { loadTencentBsp, createTencentBsp, editTencentBsp } = iceStarkData.store.get('commonAction')?.links;
const { getmongoUser, createMongoUser, editMongoUser } = iceStarkData.store.get('commonAction')?.codedb;

// import enums from '@/assets/enum.json'
import { formatDate } from 'ucode-utils';
import './../allot.scss';
const { notification } = iceStarkData.store.get('commonAction')?.ui;
import AddIcon from '@/icons/AddIcon';

const tabletr4 = ({ list, fields, notification, published }) => (
    <React.Fragment>
        {fields.map((item, index) => (

            <div className='box-body' key={index}>
                <div style={{ overflow: 'visible' }}>
                    <FormGroupField

                        name={`${item}.sceneId`}
                        component={Input}
                        type="text"
                        placeholder="请输入"
                        // list={list}
                        style={{ width: 130 }}
                        validate={validatePoolId4}
                        disabled={published}
                    // onChange={(e) => { fields.get(index).id = '' }}
                    />
                </div>
                <div style={{ overflow: 'visible' }}>
                    <FormGroupField
                        name={`${item}.value`}
                        component={NewSelect}
                        needText
                        type="text"
                        placeholder="请选择"
                        list={list.map((item) => {
                            if (fields.getAll().map((item2) => { return item2.value ? item2.value : '' }).indexOf(item.value.toString()) >= 0) {
                                return { ...item, disabled: true }
                            }
                            else {
                                return { ...item }
                            }
                        })
                        }
                        style={{ width: 130 }}
                        disabled={published}
                        validate={validatePoolPrizeId4}

                    />
                </div>
                <div>
                    {published == false ?
                        // fields.length == 1 ? '' :
                        <span className='cblue cursorp' onClick={(e) => { fields.remove(index) }} >移除</span>
                        : ''}

                </div>

            </div>
        ))}
        {published == false ?
            <div className='box-body'>
                <div style={{ width: 200 }}><AddIcon className='cursorp' onClick={(e) => { if (fields.length >= 100) { notification({ emsg: '最多100个', ecode: '500' }); } else { fields.push({}) } }}></AddIcon></div>
            </div> : ''
        }
    </React.Fragment>
)
const tabletr5 = ({ list, fields, notification, published }) => (
    <React.Fragment>
        {fields.map((item, index) => (

            <div className='box-body' key={index}>
                <div style={{ overflow: 'visible' }}>
                    <FormGroupField

                        name={`${item}.value`}
                        component={Input}
                        type="text"
                        placeholder="请输入"
                        // list={list}
                        style={{ width: 130 }}
                        validate={validatePoolId5}
                        disabled={published}
                    // onChange={(e) => { fields.get(index).id = '' }}
                    />
                </div>
                {/* <div style={{ overflow: 'visible' }}>
                    <FormGroupField
                        name={`${item}.value`}
                        type="text"
                        placeholder="请选择"
                        list={list.map((item) => {
                            if (fields.getAll().map((item2) => { return item2.value ? item2.value : '' }).indexOf(item.value.toString()) >= 0) {
                                return { ...item, disabled: true }
                            }
                            else {
                                return { ...item }
                            }
                        })
                        }
                        style={{ width: 130 }}
                        disabled={published}
                        validate={validatePoolPrizeId}

                    />
                </div> */}
                <div>
                    {published == false ?
                        // fields.length == 1 ? '' :
                        <span className='cblue cursorp' onClick={(e) => { fields.remove(index) }} >移除</span>
                        : ''}

                </div>

            </div>
        ))}
        {published == false ?
            <div className='box-body'>
                <div style={{ width: 200 }}><AddIcon className='cursorp' onClick={(e) => { if (fields.length >= 100) { notification({ emsg: '最多100个', ecode: '500' }); } else { fields.push({ value: 'CAPTCHA' }) } }}></AddIcon></div>
            </div> : ''
        }
    </React.Fragment>
)

const validatePoolId4 = (value) => {
    let value2 = '';
    if (value || value == '0') {
        value2 = value + "";
    }
    if (value2 === '' || value2.trim().length == 0) {
        return 'sceneID不能为空';
    }
    else if (value2.trim().length > 50) {
        return 'sceneID最多50个字符';
    }
}
const validatePoolPrizeId4 = (value) => {
    if (!value) {
        return '请选择活动'
    }
}
const validatePoolId5 = (value) => {
    let value2 = '';
    if (value || value == '0') {
        value2 = value + "";
    }
    if (value2 === '' || value2.trim().length == 0) {
        return '内容变量不能为空';
    }
    else if (value2.trim().length > 50) {
        return '内容变量最多50个字符';
    }
}

@withRouter
@connect(
    state => ({
        tencentBspContent: state.links.tencentBspContent,
        promotionSelectionByeseId: state.promotions.promotionSelectionByeseId,
        // LinksList: state.links.LinksList,
        userdbListSelect: state.codedb.userdbListSelect ? state.codedb.userdbListSelect.content : [],
        mongoUser: state.codedb.mongoUser,
    }),
    {

        loadTencentBsp: loadTencentBsp.REQUEST,
        createTencentBsp: createTencentBsp.REQUEST,
        editTencentBsp: editTencentBsp.REQUEST,

        getmongoUser: getmongoUser.REQUEST,
        createMongoUser: createMongoUser.REQUEST,
        editMongoUser: editMongoUser.REQUEST,
        notification: notification,
        // loadLinksList: loadLinksList.REQUEST,
        // createLink: createLink.REQUEST,
    }
)


class ViewDialog0 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            value: [],

            userbanquetLotteryDaysAndTimesCheck: false,
            user2banquetLotteryDaysAndTimesCheck: false,

            hasUserdb:false,

        }
    }
    validateName = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '天御secretID不能为空';
        }
        else if (value2.trim().length > 50) {
            return '天御secretID最多50个字符';
        }
    }
    validateName2 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '天御secretKey不能为空';
        }
        else if (value2.trim().length > 50) {
            return '天御secretKey最多50个字符';
        }
    }
    validateName3 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '天御appID不能为空';
        }
        else if (value2.trim().length > 50) {
            return '天御appID最多50个字符';
        }
    }



    validateName2_1 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '用户价值B_Id不能为空';
        }
        else if (value2.trim().length > 50) {
            return '用户价值B_Id最多50个字符';
        }
    }
    validateName2_2 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '用户价值scene_Id不能为空';
        }
        else if (value2.trim().length > 50) {
            return '用户价值scene_Id最多50个字符';
        }
    }

    validateName3_1 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '二、三要素验证secretID不能为空';
        }
        else if (value2.trim().length > 50) {
            return '二、三要素验证secretID最多50个字符';
        }
    }
    validateName3_2 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '二、三要素验证secretKey不能为空';
        }
        else if (value2.trim().length > 50) {
            return '二、三要素验证secretKey最多50个字符';
        }
    }
    validateName3_3 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2.trim().length > 50) {
            return '二、三要素验证appID最多50个字符';
        }
    }
    validateName3_4 = (value) => {
        let re = /^[1-9][0-9]*$/
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }

        // if (value2 === '' || value2.trim().length == 0) {
        //     return '不能为空';
        // }
        // else
        if (value2.trim() === '') {
        }
        else if (!re.test(value2.trim())) {
            return '请输入数字值';
        }
        else if (value2.trim() > 9999999) {
            return '最大9999999';
        }
    }


    validateName4_1 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '智能推荐secretID不能为空';
        }
        else if (value2.trim().length > 50) {
            return '智能推荐secretID最多50个字符';
        }
    }
    validateName4_2 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '智能推荐secretKey不能为空';
        }
        else if (value2.trim().length > 50) {
            return '智能推荐secretKey最多50个字符';
        }
    }
    validateName4_3 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2.trim().length > 50) {
            return '智能推荐appID最多50个字符';
        }
    }


    validateName5_1 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '腾讯短信验证码secretID不能为空';
        }
        else if (value2.trim().length > 50) {
            return '腾讯短信验证码secretID最多50个字符';
        }
    }
    validateName5_2 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '腾讯短信验证码secretKey不能为空';
        }
        else if (value2.trim().length > 50) {
            return '腾讯短信验证码secretKey最多50个字符';
        }
    }
    validateName5_3 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '腾讯短信验证码签名不能为空';
        }
        else if (value2.trim().length > 50) {
            return '腾讯短信验证码签名最多50个字符';
        }
    }
    validateName5_4 = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '腾讯短信验证码templateID不能为空';
        }
        else if (value2.trim().length > 50) {
            return '腾讯短信验证码templateID最多50个字符';
        }
    }
    validateName5_5 = (value) => {
        if (this.state.userbanquetLotteryDaysAndTimesCheck) {
            let re = /^[1-9][0-9]*$/
            let value2 = '';
            if (value || value == '0') {
                value2 = value + "";
            }

            if (value2 === '' || value2.trim().length == 0) {
                return '不能为空';
            }
            else if (value2.trim() === '') {
                return '不能为空';
            }
            else if (!re.test(value2.trim())) {
                return '请输入数字值';
            }
            else if (value2.trim() > 9999999) {
                return '最大9999999';
            }
        }
    }
    validateName5_6 = (value) => {
        if (this.state.user2banquetLotteryDaysAndTimesCheck) {
            let re = /^[1-9][0-9]*$/
            let value2 = '';
            if (value || value == '0') {
                value2 = value + "";
            }

            if (value2 === '' || value2.trim().length == 0) {
                return '不能为空';
            }
            else if (value2.trim() === '') {
                return '不能为空';
            }
            else if (!re.test(value2.trim())) {
                return '请输入数字值';
            }
            else if (value2.trim() > 9999999) {
                return '最大9999999';
            }
        }
    }

    validateName6_1 = (value) => {
        if (!value) {
            return '请选择';
        }

    }
    validateName6_2 = (value) => {
        if (value && value.length == 0) {
            return '请选择';
        }
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        const { id } = parsed;


        if (this.props.visible == 1) {
            this.props.initialize({ overdueTime: formatDate(new Date()) })

            setTimeout(() => {
                let a = this.props.tencentBspContent || [];

                let TIAN_YU = {};

                if (a.filter(key => key.securityType == 'TIAN_YU')[0]) {
                    TIAN_YU = a.filter(key => key.securityType == 'TIAN_YU')[0];
                }
                this.props.initialize(
                    {
                        secretId: TIAN_YU.secretId ? TIAN_YU.secretId : '',
                        secretKey: TIAN_YU.secretKey ? TIAN_YU.secretKey : '',
                        secretAppId: TIAN_YU.secretAppId ? TIAN_YU.secretAppId : '',
                        overdueTime: TIAN_YU.overdueTime ? formatDate(TIAN_YU.overdueTime) : formatDate(new Date()),

                    })
            }, 1);
        }
        else if (this.props.visible == 2) {

            this.props.initialize({ overdueTime: formatDate(new Date()) })

            setTimeout(() => {
                let a = this.props.tencentBspContent || [];

                let USER_VALUE = {};

                if (a.filter(key => key.securityType == 'USER_VALUE')[0]) {
                    USER_VALUE = a.filter(key => key.securityType == 'USER_VALUE')[0];
                }
                this.props.initialize(
                    {
                        secretId: USER_VALUE.secretId ? USER_VALUE.secretId : '',
                        secretKey: USER_VALUE.secretKey ? USER_VALUE.secretKey : '',
                        // secretAppId: USER_VALUE.secretAppId ? USER_VALUE.secretAppId : '',
                        overdueTime: USER_VALUE.overdueTime ? formatDate(USER_VALUE.overdueTime) : formatDate(new Date()),

                    })
            }, 1);
        }
        else if (this.props.visible == 3) {
            this.props.initialize({ overdueTime: formatDate(new Date()) })

            setTimeout(() => {
                let a = this.props.tencentBspContent || [];

                let USER_ELEMENT = {};

                if (a.filter(key => key.securityType == 'USER_ELEMENT')[0]) {
                    USER_ELEMENT = a.filter(key => key.securityType == 'USER_ELEMENT')[0];
                }
                this.props.initialize(
                    {
                        secretId: USER_ELEMENT.secretId ? USER_ELEMENT.secretId : '',
                        secretKey: USER_ELEMENT.secretKey ? USER_ELEMENT.secretKey : '',
                        secretAppId: USER_ELEMENT.secretAppId ? USER_ELEMENT.secretAppId : '',
                        overdueTime: USER_ELEMENT.overdueTime ? formatDate(USER_ELEMENT.overdueTime) : formatDate(new Date()),
                        limitNum: USER_ELEMENT.limitNum ? USER_ELEMENT.limitNum : '',

                    })
            }, 1);
        }
        else if (this.props.visible == 4) {
            this.props.initialize({ overdueTime: formatDate(new Date()) })


            setTimeout(() => {
                let a = this.props.tencentBspContent || [];

                let INTELLIGENT_PRIZE = {};

                if (a.filter(key => key.securityType == 'INTELLIGENT_PRIZE')[0]) {
                    INTELLIGENT_PRIZE = a.filter(key => key.securityType == 'INTELLIGENT_PRIZE')[0];
                }

                let newpromotionMapper = []
                console.log(INTELLIGENT_PRIZE.promotionMapper)
                for (let x in INTELLIGENT_PRIZE.promotionMapper) {
                    newpromotionMapper.push({
                        value: this.props.promotionSelectionByeseId.filter(key => key.id == x)[0] ? this.props.promotionSelectionByeseId.filter(key => key.id == x)[0] : x,
                        sceneId: INTELLIGENT_PRIZE.promotionMapper[x]
                    })

                }
                this.props.initialize(
                    {
                        secretId: INTELLIGENT_PRIZE.secretId ? INTELLIGENT_PRIZE.secretId : '',
                        secretKey: INTELLIGENT_PRIZE.secretKey ? INTELLIGENT_PRIZE.secretKey : '',
                        // secretAppId: INTELLIGENT_PRIZE.secretAppId ? INTELLIGENT_PRIZE.secretAppId : '',
                        overdueTime: INTELLIGENT_PRIZE.overdueTime ? formatDate(INTELLIGENT_PRIZE.overdueTime) : formatDate(new Date()),
                        // limitNum: INTELLIGENT_PRIZE.limitNum ? INTELLIGENT_PRIZE.limitNum : '',
                        promotionMapper: newpromotionMapper,
                    })
            }, 1);
        }
        else if (this.props.visible == 5) {
            this.props.initialize({ overdueTime: formatDate(new Date()) })


            setTimeout(() => {
                let a = this.props.tencentBspContent || [];

                let TENCENT_CAPTCHA = {};

                if (a.filter(key => key.securityType == 'TENCENT_CAPTCHA')[0]) {
                    TENCENT_CAPTCHA = a.filter(key => key.securityType == 'TENCENT_CAPTCHA')[0];
                }

                // let newcontentVariants = []
                // for (let x in TENCENT_CAPTCHA.variants) {
                //     newcontentVariants.push({ value: x, sceneId: TENCENT_CAPTCHA.variants[x] })
                // }



                if (TENCENT_CAPTCHA.phoneLimitNum && TENCENT_CAPTCHA.phoneLimitNum !== '') {
                    this.setState({ userbanquetLotteryDaysAndTimesCheck: true })
                }
                if (TENCENT_CAPTCHA.openIdLimitNum && TENCENT_CAPTCHA.openIdLimitNum !== '') {
                    this.setState({ user2banquetLotteryDaysAndTimesCheck: true })
                }




                this.props.initialize(
                    {
                        secretId: TENCENT_CAPTCHA.secretId ? TENCENT_CAPTCHA.secretId : '',
                        secretKey: TENCENT_CAPTCHA.secretKey ? TENCENT_CAPTCHA.secretKey : '',

                        //wolf
                        sign: TENCENT_CAPTCHA.sign ? TENCENT_CAPTCHA.sign : '',
                        templateId: TENCENT_CAPTCHA.templateId ? TENCENT_CAPTCHA.templateId : '',
                        isLimit: TENCENT_CAPTCHA.isLimit ? '2' : '1',

                        overdueTime: TENCENT_CAPTCHA.overdueTime ? formatDate(TENCENT_CAPTCHA.overdueTime) : formatDate(new Date()),
                        // limitNum: TENCENT_CAPTCHA.limitNum ? TENCENT_CAPTCHA.limitNum : '',
                        variants: TENCENT_CAPTCHA.variants ? TENCENT_CAPTCHA.variants.map(item => { return { value: item } }) : [{ value: 'CAPTCHA' }],

                        phoneLimitNum: TENCENT_CAPTCHA.phoneLimitNum ? TENCENT_CAPTCHA.phoneLimitNum : '',
                        openIdLimitNum: TENCENT_CAPTCHA.openIdLimitNum ? TENCENT_CAPTCHA.openIdLimitNum : '',
                    })
            }, 1);
        }
        else if (this.props.visible == 6) {
            let a = this.props.mongoUser || {};


            setTimeout(() => {
                if (a.id) {
                    this.setState({hasUserdb:true})
                    this.props.initialize(
                        {
                            ...a,
                            id: a.id,
                            userDb: { text: a.userDb, value: a.userDb },
                            indexKey: a.indexKey.split(',') || [],
                        })
                }
                else {
                    this.props.initialize(
                        {
                            userDb: '',
                            indexKey: [],
                        })
                }
            }, 1);
        }

    }
    modalOk = (value) => {
        const parsed = queryString.parse(this.props.location.search);
        const { id } = parsed;

        if (this.props.visible == 1) {
            let data = { ...value, eseId: id };
            data.overdueTime = new Date(value.overdueTime).getTime();
            data.securityType = "TIAN_YU";

            if (this.props.tencentBspContent.filter(key => key.securityType == 'TIAN_YU')[0]) {
                data.id = this.props.tencentBspContent.filter(key => key.securityType == 'TIAN_YU')[0].id
                data.version = this.props.tencentBspContent.filter(key => key.securityType == 'TIAN_YU')[0].version;
                this.props.editTencentBsp(
                    {
                        data: data,
                        callback: () => {
                            this.props.loadTencentBsp({ data: { id: id } })
                            // this.props.notification('修改成功', 'success')
                            this.props.handleCancel()
                        }
                    }
                )
            }
            else {
                this.props.createTencentBsp(
                    {
                        data: data,
                        callback: () => {
                            this.props.loadTencentBsp({ data: { id: id } })
                            // this.props.notification('修改成功', 'success')
                            this.props.handleCancel()
                        }
                    }
                )
            }

        }
        else if (this.props.visible == 2) {
            let data = { ...value, eseId: id };
            data.overdueTime = new Date(value.overdueTime).getTime();
            data.securityType = "USER_VALUE";



            if (this.props.tencentBspContent.filter(key => key.securityType == 'USER_VALUE')[0]) {
                data.id = this.props.tencentBspContent.filter(key => key.securityType == 'USER_VALUE')[0].id
                data.version = this.props.tencentBspContent.filter(key => key.securityType == 'USER_VALUE')[0].version;
                this.props.editTencentBsp(
                    {
                        data: data,
                        callback: () => {
                            this.props.loadTencentBsp({ data: { id: id } })
                            this.props.handleCancel()
                        }
                    }
                )
            }
            else {
                this.props.createTencentBsp(
                    {
                        data: data,
                        callback: () => {
                            this.props.loadTencentBsp({ data: { id: id } })
                            this.props.handleCancel()
                        }
                    }
                )
            }

        }
        else if (this.props.visible == 3) {
            let data = { ...value, eseId: id };
            data.overdueTime = new Date(value.overdueTime).getTime();
            data.securityType = "USER_ELEMENT";



            if (this.props.tencentBspContent.filter(key => key.securityType == 'USER_ELEMENT')[0]) {
                data.id = this.props.tencentBspContent.filter(key => key.securityType == 'USER_ELEMENT')[0].id
                data.version = this.props.tencentBspContent.filter(key => key.securityType == 'USER_ELEMENT')[0].version;
                this.props.editTencentBsp(
                    {
                        data: data,
                        callback: () => {
                            this.props.loadTencentBsp({ data: { id: id } })
                            this.props.handleCancel()
                        }
                    }
                )
            }
            else {
                this.props.createTencentBsp(
                    {
                        data: data,
                        callback: () => {
                            this.props.loadTencentBsp({ data: { id: id } })
                            this.props.handleCancel()
                        }
                    }
                )
            }

        }
        else if (this.props.visible == 4) {
            let data = { ...value, eseId: id };
            data.overdueTime = new Date(value.overdueTime).getTime();
            data.securityType = "INTELLIGENT_PRIZE";
            let newpromotionMapper = {}
            for (let i = 0; i < data.promotionMapper.length; i++) {
                console.log(1)
                data.promotionMapper[i].value ? data.promotionMapper[i].value.value ? newpromotionMapper[data.promotionMapper[i].value.value] = data.promotionMapper[i].sceneId : '' : ''
            }

            data.promotionMapper = newpromotionMapper
            if (this.props.tencentBspContent.filter(key => key.securityType == 'INTELLIGENT_PRIZE')[0]) {
                data.id = this.props.tencentBspContent.filter(key => key.securityType == 'INTELLIGENT_PRIZE')[0].id
                data.version = this.props.tencentBspContent.filter(key => key.securityType == 'INTELLIGENT_PRIZE')[0].version;
                this.props.editTencentBsp(
                    {
                        data: data,
                        callback: () => {
                            this.props.loadTencentBsp({ data: { id: id } })
                            this.props.handleCancel()
                        }
                    }
                )
            }
            else {
                this.props.createTencentBsp(
                    {
                        data: data,
                        callback: () => {
                            this.props.loadTencentBsp({ data: { id: id } })
                            this.props.handleCancel()
                        }
                    }
                )
            }
        }
        else if (this.props.visible == 5) {
            let data = { ...value, eseId: id };
            data.overdueTime = new Date(value.overdueTime).getTime();
            data.securityType = "TENCENT_CAPTCHA";
            let newcontentVariants = []
            for (let i = 0; i < data.variants.length; i++) {
                console.log(1)
                newcontentVariants.push(data.variants[i].value);
            }

            data.variants = newcontentVariants
            // delete data.variants

            data.isLimit = data.isLimit == '2' ? true : false;
            if (this.props.tencentBspContent.filter(key => key.securityType == 'TENCENT_CAPTCHA')[0]) {
                data.id = this.props.tencentBspContent.filter(key => key.securityType == 'TENCENT_CAPTCHA')[0].id
                data.version = this.props.tencentBspContent.filter(key => key.securityType == 'TENCENT_CAPTCHA')[0].version;
                this.props.editTencentBsp(
                    {
                        data: data,
                        callback: () => {
                            this.props.loadTencentBsp({ data: { id: id } })
                            this.props.handleCancel()
                        }
                    }
                )
            }
            else {
                this.props.createTencentBsp(
                    {
                        data: data,
                        callback: () => {
                            this.props.loadTencentBsp({ data: { id: id } })
                            this.props.handleCancel()
                        }
                    }
                )
            }

        }
        else if (this.props.visible == 6) {
            let data = { ...value, userDb: value.userDb.value, indexKey: value.indexKey.join(','), eseId: parseInt(id), status: 'ENABLE' };

            if (data.id) {
                this.props.editMongoUser(
                    {
                        data: data,
                        callback: () => {
                            this.props.getmongoUser({ data: { id: id } })
                            // this.props.notification('修改成功', 'success')
                            this.props.handleCancel()
                        }
                    }
                )
            }
            else {
                this.props.createMongoUser(
                    {
                        data: data,
                        callback: () => {
                            this.props.getmongoUser({ data: { id: id } })
                            // this.props.notification('修改成功', 'success')
                            this.props.handleCancel()
                        }
                    }
                )
            }

        }

    }

    handleChangeCheckBox5 = (key, key2) => {
        const newState = {};
        newState[key] = !this.state[key];
        this.setState(newState);

        setTimeout(() => {
            this.props.change(key2, ' ');
            this.props.change(key2, null);
        }, 1);

    }

    render() {
        const { visible, handleSubmit, handleCancel } = this.props;
        const parsed = queryString.parse(this.props.location.search);
        const { name } = parsed;


        const inputStyle = {
            width: 55,
            fontSize: 12,
            marginLeft: 10,
            marginRight: 10
        };
        const { userbanquetLotteryDaysAndTimesCheck, user2banquetLotteryDaysAndTimesCheck } = this.state;
        return (
            <div>
                {(visible == 1) &&
                    <Dialog className='tencentSafeViewDialog'
                        title={'天御'}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={{ width: '500px' }}>
                        <FormGroupList>
                            <FormGroupField

                                name='secretId'
                                label='secretID'
                                placeholder="secretID"
                                validate={this.validateName}
                                component={Input}
                            />
                            <FormGroupField

                                name='secretKey'
                                label='secretKey'
                                placeholder="secretKey"
                                component={Input}
                                validate={this.validateName2}
                            />
                            <FormGroupField

                                name='secretAppId'
                                label='appID'
                                placeholder="appID"
                                component={Input}
                                validate={this.validateName3}
                            />
                            <FormGroupField

                                name='overdueTime'
                                label='账号过期时间'
                                placeholder="账号过期时间"
                                component={DateTimePicker}
                            />
                        </FormGroupList>
                    </Dialog>}

                {(visible == 2) &&
                    <Dialog className='tencentSafeViewDialog'
                        title={'用户价值'}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={{ width: '500px' }}
                    >
                        <FormGroupList >
                            <FormGroupField

                                name='secretKey'
                                label='B_Id'
                                placeholder="B_Id"
                                validate={this.validateName2_1}
                                component={Input}
                            />
                            <FormGroupField

                                name='secretId'
                                label='scene_Id'
                                placeholder="scene_Id"
                                component={Input}
                                validate={this.validateName2_2}
                            />
                            <FormGroupField

                                name='overdueTime'
                                label='账号过期时间'
                                placeholder="账号过期时间"
                                component={DateTimePicker}
                            />
                        </FormGroupList>

                    </Dialog>}

                {(visible == 3) &&
                    <Dialog className='tencentSafeViewDialog'
                        title={'二、三要素验证'}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={{ width: '500px' }}
                    >
                        <FormGroupList >
                            <FormGroupField

                                name='secretId'
                                label='secretID'
                                placeholder="secretID"
                                validate={this.validateName3_1}
                                component={Input}
                            />
                            <FormGroupField

                                name='secretKey'
                                label='secretKey'
                                placeholder="secretKey"
                                component={Input}
                                validate={this.validateName3_2}
                                type='password'
                            />
                            <FormGroupField

                                name='secretAppId'
                                label='appID'
                                placeholder="appID"
                                component={Input}
                                validate={this.validateName3_3}
                            />
                            <FormGroupField

                                name='overdueTime'
                                label='账号过期时间'
                                placeholder="账号过期时间"
                                component={DateTimePicker}
                            />
                            <FormGroupField

                                name='limitNum'
                                label='验证限制'
                                placeholder="每个用户每天最多验证次数"
                                component={Input}
                                validate={this.validateName3_4}
                            />
                        </FormGroupList>

                    </Dialog>}

                {(visible == 4) &&
                    <Dialog className='tencentSafeViewDialog'
                        title={'智能推荐'}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={{ width: '500px' }}
                    >
                        <FormGroupList >
                            <FormGroupField

                                name='secretId'
                                label='secretID'
                                placeholder="secretID"
                                validate={this.validateName4_1}
                                component={Input}
                            />
                            <FormGroupField

                                name='secretKey'
                                label='secretKey'
                                placeholder="secretKey"
                                component={Input}
                                validate={this.validateName4_2}
                            />
                            {/* <FormGroupField

                            name='secretAppId'
                            label='appID'
                            placeholder="appID"
                            component={Input}
                            validate={this.validateName4_3}
                        /> */}
                            <FormGroupField

                                name='overdueTime'
                                label='账号过期时间'
                                placeholder="账号过期时间"
                                component={DateTimePicker}
                            />
                            {/* <FormGroupField

                            name='limitNum'
                            label='验证限制'
                            placeholder="每个用户每天最多验证次数"
                            component={Input}
                            validate={this.validateName4_4}
                        /> */}
                        </FormGroupList>


                        <div style={{ marginBottom: 20, marginTop: 20 }} >
                            <div style={{ display: 'inline-block', width: '50px', verticalAlign: 'top', color: '#888' }}> 列表</div>

                            <div className='box' style={{ display: 'inline-block' }}>
                                <div className='box-head' >
                                    <div>推荐sceneID</div>
                                    <div>营销活动</div>
                                    <div>操作</div>
                                </div>
                                <FieldArray name="promotionMapper" component={tabletr4} list={this.props.promotionSelectionByeseId || []} notification={this.props.notification} published={false} />

                            </div>
                        </div>



                    </Dialog>}

                {(visible == 5) &&
                    <Dialog className='tencentSafeViewDialog'
                        title={'腾讯短信验证码'}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={{ width: '500px' }}
                    >
                        <FormGroupList >
                            <FormGroupField
                                name='secretId'
                                label='secretID'
                                placeholder="secretID"
                                validate={this.validateName5_1}
                                component={Input}
                            />
                            <FormGroupField
                                name='secretKey'
                                label='secretKey'
                                placeholder="secretKey"
                                component={Input}
                                validate={this.validateName5_2}
                            />
                            <FormGroupField
                                name='sign'
                                label='签名'
                                placeholder="签名"
                                component={Input}
                                validate={this.validateName5_3}
                            />
                            <FormGroupField

                                name='templateId'
                                label='templateID'
                                placeholder="templateID"
                                component={Input}
                                validate={this.validateName5_4}
                            />
                            <FormGroupField

                                name='overdueTime'
                                label='账号过期时间'
                                placeholder="账号过期时间"
                                component={DateTimePicker}
                            />

                        </FormGroupList>


                        <div style={{ marginBottom: 20, marginTop: 20 }} >
                            <div style={{ display: 'inline-block', width: '50px', verticalAlign: 'top', color: '#888' }}> 列表</div>

                            <div className='box' style={{ display: 'inline-block' }}>
                                <div className='box-head' >
                                    <div>内容变量</div>
                                    <div>操作</div>
                                </div>
                                <FieldArray name="variants" component={tabletr5} notification={this.props.notification} published={false} />

                            </div>
                        </div>




                        <FormGroupList>
                            <FormGroupField

                                name='isLimit'
                                label='短信发送限制'
                                placeholder="短信发送限制"
                                component={RadioGroup}
                                list={[{ value: '1', text: '不限制' }, { value: '2', text: '限制' }]}
                            />
                        </FormGroupList>

                        <div className="line-block" style={{ marginLeft: 77 }}>
                            <div className="itemClass">
                                <Checkbox
                                    style={{ marginLeft: 15 }}
                                    checked={userbanquetLotteryDaysAndTimesCheck}
                                    onChange={() => { this.handleChangeCheckBox5('userbanquetLotteryDaysAndTimesCheck', 'phoneLimitNum') }}
                                />
                            </div>
                            {/* <div className="itemClass">每</div> */}
                            <div className="itemClass">

                                <FormGroupField
                                    label="每个手机号每天最多"
                                    name={'phoneLimitNum'}
                                    component={Input}
                                    style={inputStyle}
                                    disabled={!userbanquetLotteryDaysAndTimesCheck}
                                    validate={this.validateName5_5}
                                />

                            </div>
                            <div className="itemClass">次</div>
                        </div>



                        <div className="line-block" style={{ marginLeft: 77 }}>
                            <div className="itemClass">
                                <Checkbox
                                    style={{ marginLeft: 15 }}
                                    checked={user2banquetLotteryDaysAndTimesCheck}
                                    onChange={() => { this.handleChangeCheckBox5('user2banquetLotteryDaysAndTimesCheck', 'openIdLimitNum') }}
                                />
                            </div>
                            {/* <div className="itemClass">每</div> */}
                            <div className="itemClass">

                                <FormGroupField
                                    label="每个openID每天最多"
                                    name={'openIdLimitNum'}
                                    component={Input}
                                    style={inputStyle}
                                    disabled={!user2banquetLotteryDaysAndTimesCheck}
                                    validate={this.validateName5_6}
                                />

                            </div>
                            <div className="itemClass">次</div>
                        </div>
                    </Dialog>}

                {(visible == 6) &&
                    <Dialog className='tencentSafeViewDialog'
                        title={'营销用户库'}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={{ width: '500px' }}
                    >
                        <p>用户库选择后不可修改，请确认后操作</p>
                        <FormGroupList >
                            <FormGroupField

                                name='userDb'
                                label='用户库'
                                placeholder="请选择"
                                list={this.props.userdbListSelect.map(item => { return { ...item, text: item.name, value: item.name } })}
                                validate={this.validateName6_1}
                                component={NewSelect}
                                needText
                                disabled={this.state.hasUserdb}
                                style={{ width: '330px' }}
                            />
                            <FormGroupField

                                name='indexKey'
                                label='索引字段'
                                placeholder="请选择"
                                component={MultipleSelect}
                                style={{ width: '330px' }}
                                list={[{ text: 'um.opi', value: 'um.opi' },
                                { text: 'um.phone', value: 'um.phone' },
                                { text: 'um.mid', value: 'um.mid' },
                                ]}
                                validate={this.validateName6_2}
                            />
                        </FormGroupList>

                    </Dialog>}
            </div>
        )
    }
}



const ViewDialog = reduxForm({
    form: 'ViewDialog',
})(ViewDialog0);
export default ViewDialog;