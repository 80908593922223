import React from 'react';
import { connect } from 'react-redux';

import { FormGroupField } from 'tyb'
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
const { loadTencentBsp, umit } = iceStarkData.store.get('commonAction')?.links;
const { loadUserdbSelect ,getmongoUser} = iceStarkData.store.get('commonAction')?.codedb;
const { getPromotionSelectionByeseId } = iceStarkData.store.get('commonAction')?.promotions;
import { DataTable } from 'tyb';
import './allot.scss';


import TencentSafeViewDialog from './components/TencentSafeViewDialog';
@withRouter
@connect(
    state => ({
        tencentBspContent: state.links.tencentBspContent,
        //  promotionSelectionByeseId: state.promotions.promotionSelectionByeseId,
    }),
    {
        loadUserdbSelect:loadUserdbSelect.REQUEST,
        loadTencentBsp: loadTencentBsp.REQUEST,
        getPromotionSelectionByeseId: getPromotionSelectionByeseId.REQUEST,
        getmongoUser:getmongoUser.REQUEST,
        umit: umit.REQUEST,
    }
)




class CodeTempConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableColumns: this.getTabColumns(),
            type: 0,
            value: [],
            dialog: {
                show: false,
            }
        }
        this.list = [
            { name: '天域安全', index: 1, },
            { name: '用户价值', index: 2, },
            { name: '二三要素', index: 3, },
            { name: '智能推荐', index: 4, },
            { name: '腾讯短信验证码', index: 5, },
            { name: '营销用户库', index: 6, },

        ]
    }
    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        const { id } = parsed;
        this.props.umit()
        this.props.getPromotionSelectionByeseId({ eseId: id });

        this.props.loadUserdbSelect({})
        this.props.getmongoUser({
            data: { id: id },
            callback: () => { }
        })

        this.props.loadTencentBsp({
            data: { id: id },
            callback: () => {
                // setTimeout(() => {
                //     let a = this.props.tencentBspContent;

                //     let TIAN_YU = {};
                //     let USER_VALUE = {};
                //     let USER_ELEMENT = {};

                //     if (a.filter(key => key.securityType == 'TIAN_YU')[0]) {
                //         TIAN_YU = a.filter(key => key.securityType == 'TIAN_YU')[0];
                //     }
                //     if (a.filter(key => key.securityType == 'USER_VALUE')[0]) {
                //         USER_VALUE = a.filter(key => key.securityType == 'USER_VALUE')[0];
                //     }
                //     if (a.filter(key => key.securityType == 'USER_ELEMENT')[0]) {
                //         USER_ELEMENT = a.filter(key => key.securityType == 'USER_ELEMENT')[0];
                //     }
                //     this.props.initialize(
                //         {
                //             secretId: TIAN_YU.secretId ? TIAN_YU.secretId : '',
                //             secretKey: TIAN_YU.secretKey ? TIAN_YU.secretKey : '',
                //             secretAppId: TIAN_YU.secretAppId ? TIAN_YU.secretAppId : '',
                //             overdueTime: TIAN_YU.overdueTime ? formatDate(TIAN_YU.overdueTime) : formatDate(new Date()),


                //             secretId2: USER_VALUE.secretId ? USER_VALUE.secretId : '',
                //             secretKey2: USER_VALUE.secretKey ? USER_VALUE.secretKey : '',
                //             overdueTime2: USER_VALUE.overdueTime ? formatDate(USER_VALUE.overdueTime) : formatDate(new Date()),

                //             secretId3: USER_ELEMENT.secretId ? USER_ELEMENT.secretId : '',
                //             secretKey3: USER_ELEMENT.secretKey ? USER_ELEMENT.secretKey : '',
                //             secretAppId3: USER_ELEMENT.secretAppId ? USER_ELEMENT.secretAppId : '',
                //             overdueTime3: USER_ELEMENT.overdueTime ? formatDate(USER_ELEMENT.overdueTime) : formatDate(new Date())

                //         })
                // }, 1);

            }
        })



    }

    getTabColumns = () => {
        // const { permissionIds } = this.props;
        return [
            {
                key: 'name',
                dataIndex: 'name',
                title: '配置',
            },

            {
                key: 'op',
                dataIndex: 'op',
                title: '操作',
                render: (value, record) =>
                    <div className="table-operate-btn">
                        <span onClick={() => {
                            this.handleAllot(record.index)
                        }}>设置</span>
                    </div>
            },
        ]
    }

    getColumns = () => {
        return this.getTabColumns().map(column => {
            return column;
        });
    }
    handleAllot = (index) => {
        let dialog = {
            show: true,
            index
        }
        this.setState({ dialog })
    }
    handleCancel = () => {
        let dialog = {
            show: false,
        }
        this.setState({ dialog })
    }


    componentWillUnmount() {

    }
    render() {

        const parsed = queryString.parse(this.props.location.search);
        const { name } = parsed;
        return (

            <div className="allotTencentSafer">
                <div className='title'>{name}</div>
                <div className="main-block" style={{ padding: 0, border: 'none' }}>

                    <DataTable
                        // bodyStyle={{ width: '100%', maxWidth: '100%' }}
                        columns={this.getColumns()}
                        data={this.list}
                        rowKey="id"
                    />

                </div>
                {this.state.dialog.show && <TencentSafeViewDialog visible={this.state.dialog.index} handleCancel={this.handleCancel} dialogData={this.state.dialog}></TencentSafeViewDialog>}
            </div>
        )
    }
}

export default CodeTempConfig;