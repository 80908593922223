import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form'
import { FormGroupList, Input, MultipleSelect, Button, } from 'tyb';

import { FormGroupField } from 'tyb'
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
const { loadLabelNumber, createLabelNumber, editLabelNumber } = iceStarkData.store.get('commonAction')?.memberManage;

// import enums from '@/assets/enum.json'
import { formatDate } from 'ucode-utils';
import './allot.scss';




@withRouter
@connect(
    state => ({
        labelNumberContent: state.memberManage.labelNumberContent,
        // LinksList: state.links.LinksList,
    }),
    {
        loadLabelNumber: loadLabelNumber.REQUEST,
        createLabelNumber: createLabelNumber.REQUEST,
        editLabelNumber: editLabelNumber.REQUEST
        // loadLinksList: loadLinksList.REQUEST,
        // createLink: createLink.REQUEST,
    }
)
class CodeTempConfig0 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            value: [],
        }
    }
    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        const { id } = parsed;
        this.props.loadLabelNumber({
            data: { id: id },
            callback: () => {
                setTimeout(() => {
                    let a = this.props.labelNumberContent;
                    this.props.initialize(
                        {
                            userDefinedTagCount: a.userDefinedTagCount ? a.userDefinedTagCount : '',
                            manualTagCount: a.manualTagCount ? a.manualTagCount : '',
                            membershipTagCount: a.membershipTagCount ? a.membershipTagCount : '',
                            sizersCount:a.sizersCount ? a.sizersCount : '5',
                        })
                }, 1);

            }
        })

    }

    modalOk = (value) => {
        const parsed = queryString.parse(this.props.location.search);
        const { id } = parsed;
        let data = { ...value, eseId: id };
        if (this.props.labelNumberContent.id && this.props.labelNumberContent.version) {
            data.id = this.props.labelNumberContent.id
            data.version = this.props.labelNumberContent.version;
            this.props.editLabelNumber(
                {
                    data: data,
                    callback: () => {
                        this.props.loadLabelNumber({ data: { id: id } })
                        // this.props.initialize({ overdueTime: formatDate(new Date()) })
                    }
                }
            )
        }
        else {
            this.props.createLabelNumber(
                {
                    data: data,
                    callback: () => {
                        this.props.loadLabelNumber({ data: { id: id } })
                        // this.props.initialize({ overdueTime: formatDate(new Date()) })
                    }
                }
            )
        }

    }

    validateName = (value) => {
      
        let re = /^[1-9][0-9]*$/ 
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '不能为空';
        }
        // else if (value2.trim() === '0') {
        //     return '不能为0';
        // }
        else if (!re.test(value2.trim())) {
            return '请输入数字值';
        }
        else if (value2.trim() > 9999999) {
            return '最大9999999';
        }
    }
    validateName2 = (value) => {
        let re = /^[1-9][0-9]*$/ 
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '不能为空';
        }
        // else if (value2.trim() === '0') {
        //     return '不能为0';
        // }
        else if (!re.test(value2.trim())) {
            return '请输入数字值';
        }
        else if (value2.trim() > 9999999) {
            return '最大9999999';
        }
    }
    validateName3 = (value) => {
        let re = /^[1-9][0-9]*$/ 
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '不能为空';
        }
        // else if (value2.trim() === '0') {
        //     return '不能为0';
        // }
        else if (!re.test(value2.trim())) {
            return '请输入数字值';
        }
        else if (value2.trim() > 9999999) {
            return '最大9999999';
        }
    }

    render() {

        const parsed = queryString.parse(this.props.location.search);
        const { name } = parsed;
        return (


            <div className="allotTencentSafer">
                <div className='title'>{name}</div>
                <div className="main-block">
                    <p>标签开放数量设置</p>
                    <div style={{ marginBottom: 15 }}>
                        <FormGroupList >
                            <FormGroupField

                                name='userDefinedTagCount'
                                label='自定义标签'
                                placeholder="自定义标签"
                                validate={this.validateName}
                                component={Input}
                            />
                            <FormGroupField

                                name='manualTagCount'
                                label='手动标签'
                                placeholder="手动标签"
                                component={Input}
                                validate={this.validateName2}
                            />
                            <FormGroupField

                                name='membershipTagCount'
                                label='会员分组'
                                placeholder="会员分组"
                                component={Input}
                                validate={this.validateName3}
                            />

                        </FormGroupList>

                    </div>
                    <div className='ml20 mr20' style={{ paddingTop: 15, borderTop: '1px solid #ddd' }}>


                        <Button className="tc-15-btn" onClick={this.props.handleSubmit(this.modalOk)}>完成</Button>

                    </div>
                </div>

            </div>
        )
    }
}


const CodeTempConfig = reduxForm({
    form: 'awardZHForm',

})(CodeTempConfig0);


export default CodeTempConfig;