import React from 'react';
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { Pager, DataTable, Button } from 'tyb';
const { changeDictPaging, singleCodeQueryToolTypeList, singleCodeQueryToolTypeStatus, singleCodeQueryToolTypeDelete } = iceStarkData.store.get('commonAction')?.singleCodeQueryToolType;
import { timestampToTime } from 'ucode-utils';
import { IProps, IState } from './variableTypes'
import AddAndEditDialog from './Components/AddAndEditDialog'

import './SingleCodeQueryToolType.scss'

class SingleCodeQueryToolType0 extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          key: 'filedName',
          dataIndex: 'filedName',
          title: '条件名称',
          render: (val, record) => {
            let preVal = val;
            if (typeof val === 'string' && val.length > 20) {
              val = val.slice(0, 20) + '...'
            }
            return <div className='filedName' title={preVal} style={{ color: `${record.status === 'ENABLE' ? '' : '#bebebe'}` }}>{val}</div>
          }
        },
        {
          key: 'filedCode',
          dataIndex: 'filedCode',
          title: '条件编码',
          render: (val, record) => {
            let preVal = val;
            if (typeof val === 'string' && val.length > 20) {
              val = val.slice(0, 30) + '...'
            }
            return <div className='filedCode' title={preVal} style={{ color: `${record.status === 'ENABLE' ? '' : '#bebebe'}` }}>{val}</div>
          }
        },
        {
          key: 'remark',
          dataIndex: 'remark',
          title: '备注',
          render: (val, record) => {
            let preVal = val;
            if (typeof val === 'string' && val.length > 20) {
              val = val.slice(0, 20) + '...'
            }
            return <div className='remark' title={preVal} style={{ color: `${record.status === 'ENABLE' ? '' : '#bebebe'}` }}>{val}</div>
          }
        },
        {
          key: 'createdTime',
          dataIndex: 'createdTime',
          title: '创建时间',
          render: (val, record) => {
            return <div title={timestampToTime(val)} style={{ color: `${record.status === 'ENABLE' ? '' : '#bebebe'}` }}>{timestampToTime(val)}</div>
          }
        },
        {
          key: 'operate',
          dataIndex: 'operate',
          title: '操作',
          render: (val, record) => {
            return (
              <div className='operation-span'>
                {
                  this.props.permissionIds.includes('data.modelFileds.edit') &&
                  <span onClick={() => { this.showDialog('edit', record); }}>编辑</span>
                }
                {
                  this.props.permissionIds.includes('data.modelFileds.status') &&
                  <span onClick={() => { this.updateStatus(record); }}>{record.status === 'ENABLE' ? '禁用' : '启用'}</span>
                }
                {
                  !record.sysDef &&
                  this.props.permissionIds.includes('data.modelFileds.deleted') &&
                  <span onClick={() => { this.deleteRecord(record); }}>删除</span>
                }
              </div>
            )
          }
        }
      ],
      showDialog: false,
      type: '',
      record: {},
      title: '',
      page: 0,
      size: 20,
      sort: 'createdTime,desc',
      work: ''
    }
  }

  componentDidMount() {
    const { page, size, sort, work } = this.state;
    const { eseId } = this.props;
    this.props.changeDictPaging({ page, size });
    this.props.getSingleCodeQueryToolTypeList({ page, size, sort, work, eseId, requestLatestData: this.requestLatestData });
  }

  render() {
    const { enterPriseName, singleCodeQueryToolTypeData = {}, dictPaging, eseId } = this.props;
    const { content = [], totalElements } = singleCodeQueryToolTypeData;
    const { columns, showDialog, title, record, type, page, size, sort, work } = this.state;

    return (
      <div className='single-code-query-tool-type'>
        <div className='title'>{enterPriseName}</div>
        <div className='body'>
          {
            this.props.permissionIds.includes('data.modelFileds.add') &&
            <Button className='add' onClick={() => { this.showDialog('add', {}) }}>创建</Button>
          }

          <DataTable
            columns={columns}
            data={content}
          />

          {
            // content &&
            // content.length > 0 &&
            <Pager
              total={totalElements}
              current={dictPaging.page}
              perPageCountList={[10, 20, 50]}
              perPageCount={dictPaging.size}
              onPageChange={e => {
                this.setState({ page: e })
                this.props.changeDictPaging({ ...dictPaging, page: e });
                this.props.getSingleCodeQueryToolTypeList({ ...dictPaging, page: e, sort, work, eseId, requestLatestData: this.requestLatestData });
              }}
              onPerPageChange={e => {
                this.setState({ size: e })
                this.props.changeDictPaging({ ...dictPaging, size: e });
                this.props.getSingleCodeQueryToolTypeList({ ...dictPaging, size: e, page, sort, work, eseId, requestLatestData: this.requestLatestData });
              }}
            />
          }

          {
            showDialog &&
            <AddAndEditDialog
              visible={showDialog}
              title={title}
              record={record}
              type={type}
              eseId={eseId}
              hideDialog={this.hideDialog}
              requestLatestData={this.requestLatestData.bind(this)}
              content={content}
            />
          }
        </div>
      </div>
    )
  }

  deleteRecord: (record) => void = (record) => {
    const { page, size, sort, work } = this.state;
    const { eseId } = this.props;

    this.props.deleteSingleCodeQueryToolType({
      id: record.id, eseId, requestLatestData: () => {
        this.props.getSingleCodeQueryToolTypeList({ page, size, sort, work, eseId, requestLatestData: this.requestLatestData });
      }
    });
  }


  updateStatus: (record) => void = (record) => {
    let { id, status } = record;
    const { page, size, sort, work } = this.state;
    const { eseId } = this.props;
    status = status === 'DISABLE' ? 'ENABLE' : 'DISABLE';

    this.props.updateSingleCodeQueryToolTypeStatus({
      id, status, eseId, requestLatestData: () => {
        this.props.getSingleCodeQueryToolTypeList({ page, size, sort, work, eseId, requestLatestData: this.requestLatestData });
      }
    });
  }

  showDialog: (type: string, record) => void = (type: string, record) => {
    if (type === 'add') {
      this.setState({ title: '创建', type });
    } else if (type === 'edit') {
      this.setState({ title: '编辑', record, type });
    }

    this.setState({ showDialog: true });
  }

  hideDialog: () => void = () => {
    this.setState({ showDialog: false });
  }

  requestLatestData = (response) => {   // 接口请求数据的回调函数
    let { content } = response;

    let min = null;
    for (let i = 0; i < content.length; i++) {    // 根据id从小到大排序
      for (let j = i; j < content.length; j++) {
        if (content[i].id > content[j].id) {
          min = content[j];
          content[j] = content[i];
          content[i] = min;
        }
      }
    }
    response.content = content;
    return response;
  }

}

const SingleCodeQueryToolType = reduxForm({
  form: 'SingleCodeQueryToolTypeForm'
})(connect(
  (state: IStore) => ({
    permissionIds: state.users.permissionIds,
    dictPaging: state.singleCodeQueryToolType.dictPaging,
    singleCodeQueryToolTypeData: state.singleCodeQueryToolType.singleCodeQueryToolTypeData,
  }),
  {
    changeDictPaging,
    getSingleCodeQueryToolTypeList: singleCodeQueryToolTypeList.REQUEST,
    updateSingleCodeQueryToolTypeStatus: singleCodeQueryToolTypeStatus.REQUEST,
    deleteSingleCodeQueryToolType: singleCodeQueryToolTypeDelete.REQUEST,
  }
)(SingleCodeQueryToolType0));

export default SingleCodeQueryToolType;