import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;

import CodeConfiguration from './CodeConfiguration';
import DataCenter from './DataCenter';
import DataGet from './DataGet';

import TencentSafe from './TencentSafe';
import NoStatisticsconfig from './NoStatisticsconfig';
import LabelNumber from './LabelNumber';
import SingleCodeQueryToolType from './SingleCodeQueryToolType/SingleCodeQueryToolType';

import { TabList } from 'tyb';
import { TabModified } from 'ucode-components';



@connect(
  state => ({
    currentStep: state.codePrintingConfig.currentStep,
  }),
  {
    setNavBar,
  }
)
class EditCodePrintingConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this.props.setNavBar('资源分配');

    const parsed = queryString.parse(this.props.location.search);
    const { id } = parsed;
  }


  getTabList = (promotionType) => {
    switch (promotionType) {
      case 'QR_CODE':
      default:
        return [
          {
            text: '码配置',
            value: 0,
          },
          // 迎驾贡注释
          // {
          //   text: '数据中心',
          //   value: 1,
          // }, {
          //   text: '营销安全',
          //   value: 2,
          // }, {
          //   text: '不统计配置',
          //   value: 3,
          // }, {
          //   text: '标签数量',
          //   value: 4,
          // }, {
          //   text: '主数据获取',
          //   value: 5,
          // }, {
          //   text: '单码查询工具类型',
          //   value: 6,
          // }
        ];
    }
  }

  handleOnChange = (val) => {
    const { location, history } = this.props;
    const parsed = queryString.parse(location.search);
    parsed.type = val;
    const path = location.pathname;
    history.replace(`${path}?${queryString.stringify(parsed)}`);
  }



  render() {

    const parsed = queryString.parse(this.props.location.search);
    const { type = '0', id: eseId, name = '' } = parsed;

    return (
      <div className="">

        <TabModified>
          <TabList
            list={this.getTabList('QR_CODE')}
            value={parseFloat(type)}
            onChange={(val) => this.handleOnChange(val)}
          />
        </TabModified>
        <div>
          {(type === '0') &&
            <CodeConfiguration />
          }
          {(type === '1') &&
            <DataCenter />
          }
          {(type === '2') &&
            <TencentSafe />
          }

          {type == 3 &&
            <NoStatisticsconfig />
          }
          {(type === '4') &&
            <LabelNumber />
          }
          {(type === '5') &&
            <DataGet />
          }
          {(type === '6') &&
            <SingleCodeQueryToolType
              eseId={parseInt(eseId, 10)}
              enterPriseName={name}
            />
          }

          {/* {(step === '2' && !this.props.loadCodePrintingConfigByIdLoading) &&
            <Step2 />
          }

          {(step === '3' && !this.props.loadCodePrintingConfigByIdLoading) &&
            <Step3 />
          }  */}
        </div>
      </div>
    )
  }
}

export default EditCodePrintingConfig;