import React from 'react';
import { FieldArray, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { FormGroupList, Button, RadioGroup} from 'tyb';
import { FormGroupField } from 'tyb'
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
const { notification } = iceStarkData.store.get('commonAction')?.ui;
const { loadEseConfigs, editEseConfigs, umit } = iceStarkData.store.get('commonAction')?.links;
import './allot.scss';

@withRouter
@connect(
    state => ({
        eseConfigs: state.links.eseConfigs || {},
    }),
    {
        loadEseConfigs: loadEseConfigs.REQUEST,
        editEseConfigs: editEseConfigs.REQUEST,
        umit: umit.REQUEST,
        notification,
    }
)




class ViewDialog0 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            value: [],

        }
    }
    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        const { id } = parsed;
        this.props.umit()
        this.props.loadEseConfigs({
            data: { id: id }, callback: (data) => {

                let a = data;

                this.props.initialize(
                    {
                        allProduct: a.allProduct ? '1' : '2',
                        allDealer: a.allDealer ? '1' : '2',
                        allShop: a.allShop ? '1' : '2',
                    })

            }
        })


    }
    modalOk = (value) => {
        const parsed = queryString.parse(this.props.location.search);
        const { id } = parsed;
        let data = { ...value, eseId: id };

        data.allProduct = data.allProduct == '2' ? false : true;
        data.allDealer = data.allDealer == '2' ? false : true;
        data.allShop = data.allShop == '2' ? false : true;
        this.props.editEseConfigs(
            {
                data: data,
                callback: () => {
                    this.props.loadEseConfigs({ data: { id: id } })
                    this.props.notification('修改成功', 'success')
                }
            }
        )
    }

    componentWillUnmount() {

    }
    render() {

        const parsed = queryString.parse(this.props.location.search);
        const { name } = parsed;
        return (

            <div className="allotDataGet">
                <div className='title'>{name}</div>
                <div className="main-block" >


                    <div style={{ marginBottom: 15, display: 'inline-block', marginRight: 15, verticalAlign: 'top' }}>
                        <div style={{ marginBottom: 10 }}>主数据选择方式</div>
                        <div style={{ border: '1px solid #ddd', padding: 15, paddingRight: 100 }}>
                            <div style={{ height: 260, overflow: 'auto' }}>

                                <FormGroupList>
                                    <FormGroupField

                                        name='allProduct'
                                        label='产品数据'
                                        placeholder="产品数据"
                                        component={RadioGroup}
                                        list={[{ value: '1', text: '默认全部' }, { value: '2', text: '搜索导入' }]}
                                    />
                                    <FormGroupField

                                        name='allDealer'
                                        label='经销商数据'
                                        placeholder="经销商数据"
                                        component={RadioGroup}
                                        list={[{ value: '1', text: '默认全部' }, { value: '2', text: '搜索导入' }]}
                                    />
                                    <FormGroupField

                                        name='allShop'
                                        label='终端门店数据'
                                        placeholder="终端门店数据"
                                        component={RadioGroup}
                                        list={[{ value: '1', text: '默认全部' }, { value: '2', text: '搜索导入' }]}
                                    />
                                </FormGroupList>
                            </div>
                            <Button className="tc-15-btn" onClick={this.props.handleSubmit(this.modalOk)}>完成</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const ViewDialog = reduxForm({
    form: 'ViewDialog',
})(ViewDialog0);
export default ViewDialog;