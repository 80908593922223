import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form'
import { FormGroupList, Input, Button, ListSelector } from 'tyb';
import { MultipleSelect } from 'ucode-components';
import { FormGroupField } from 'tyb'
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
const { loadLinkSelections, createLink, loadLinksList } = iceStarkData.store.get('commonAction')?.links;
import { NewSelect } from 'ucode-components';
import enums from '@/assets/enum.json';


import './allot.scss';


const setting = (props) => {
    return (
        <p style={{ fontSize: '12px', marginBottom: 0 }}>{props.showValue}</p>
    )
}

const validateName = (value) => {
    if (!value) {
        return '请选择更新码状态'
    }
}
const validateName2 = (value) => {
    if (!value) {
        return '请选择文件类型'
    }
}
const validateName3 = (value) => {
    // if (value.length == 0) {
    //     return '请选择营销使用字段'
    // }
}

const table = ({ list, fields, dispatch, published }) => (
    <div>
        {fields.map((item, index) => (

            <div style={{ display: 'inline-block', width: 480, padding: 15, marginRight: 20, border: '1px solid #ddd', marginBottom: 20 }} key={index}>
                {/* {JSON.stringify(Object.values(enums.optionStatus))} */}
                <FormGroupList >
                    <FormGroupField

                        name={`${item}.name`}
                        label='环节'
                        placeholder="环节"
                        showValue={list.filter(key => key.code == fields.get(index).linkCode)[0].text}
                        component={setting}
                    />
                    <FormGroupField
                        name={`${item}.limitStatus`}

                        label='前置条件'
                        placeholder="前置条件"
                        // validate={this.validateName2}
                        component={MultipleSelect}
                        list={Object.keys(enums.limitStatus).map(item => { return { value: item, text: enums.limitStatus[item].name } })}
                        style={{ width: 330 }}
                    />


                    <FormGroupField
                        name={`${item}.optionStatus`}

                        label='更新码状态'
                        placeholder="更新码状态"
                        validate={validateName}
                        required
                        component={NewSelect}
                        style={{ width: 330 }}
                        list={Object.keys(enums.optionStatus).map(item => { return { value: item, text: enums.optionStatus[item].name } })}
                    />
                    <FormGroupField
                        name={`${item}.fileType`}

                        label='文件类型'
                        placeholder="文件类型"
                        required
                        validate={validateName2}
                        component={NewSelect}
                        style={{ width: 330 }}
                        list={Object.keys(enums.fileType).map(item => { return { value: item, text: enums.fileType[item].name } })}
                    />
                    <FormGroupField
                        name={`${item}.fieldCodes`}

                        label='营销使用字段'
                        placeholder="营销使用字段"
                        // required
                        validate={validateName3}
                        component={MultipleSelect}
                         list={list.filter(key => key.code == fields.get(index).linkCode)[0].fields?list.filter(key => key.code == fields.get(index).linkCode)[0].fields.map(item => { return { value: item.code, text: item.name + '_' + item.code } }):[]}
                        style={{ width: 330 }}
                   
                    />

                </FormGroupList>
            </div>
        ))}

        {/* {published == false ? <div style={{ width: 50 }} className='cblue cursorp' onClick={(e) => { if (fields.length >= 20) { dispatch(uiActions.notification({ emsg: '最多20个', ecode: '500' })); } else { fields.push({ probabilityPrizes: [{}] }) } }}>添加奖品</div> : ''} */}
    </div>

)


@withRouter
@connect(
    state => ({
        linksSelectionsList: state.links.linksSelectionsList,
        LinksList: state.links.LinksList,
    }),
    {
        loadLinkSelections: loadLinkSelections.REQUEST,
        loadLinksList: loadLinksList.REQUEST,
        createLink: createLink.REQUEST,
    }
)
class CodeTempConfig0 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            value: [],
        }
    }
    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        const { id } = parsed;
        this.props.loadLinkSelections({
            status: 'ENABLE',
            callback: () => {
                this.props.loadLinksList({
                    data: { id },
                    callback: () => {

                        setTimeout(() => {
                            if (this.props.LinksList.content.length > 0) {
                                let a = this.props.LinksList.content.map(item => { return item.linkCode })
                                let b = [];
                                for (let i = 0; i < a.length; i++) {
                                    this.props.linksSelectionsList.filter(key => key.code == a[i])[0] ? b.push(this.props.linksSelectionsList.filter(key => key.code == a[i])[0].id) : '';
                                    console.log(this.props.linksSelectionsList)
                                }
                                this.setState({ value: b, type: 1 })
                                // let prizeCombinations = [];
                                // for (let i = 0; i < this.props.LinksList.content.length; i++) {
                                //     let b = this.props.LinksList.content[i]
                                //     prizeCombinations.push({
                                //         id: b.id,
                                //         version: b.version,
                                //         linkCode: b.linkCode,
                                //         limitStatus: b.limitStatus,
                                //         fieldCodes: b.fieldCodes,
                                //         optionStatus: b.optionStatus,
                                //         fileType: b.fileType,
                                //     })
                                // }
                                // this.props.initialize({ prizeCombinations });


                            }
                            else {
                                this.setState({ type: 1 })
                            }
                        }, 1);

                    }
                })
            }
        })





    }


    DummyTransferChange = (a) => {
        this.setState({ value: a })
    }

    goNext = (value) => {
        if (value == 1) {
            if (this.state.value.length > 0) {
                this.setState({ type: 2 })
                let prizeCombinations = [];
                let values = this.state.value.sort();
                for (let i = 0; i < values.length; i++) {
                    let a = this.props.LinksList.content.map(item => { return item.linkCode })
                    if (a.includes(this.props.linksSelectionsList.filter(key => key.id == values[i])[0].code)) {

                        let b = this.props.LinksList.content[a.indexOf(this.props.linksSelectionsList.filter(key => key.id == values[i])[0].code)]
                        let selects= [];

                        this.props.linksSelectionsList.filter(v=> v.code == b.linkCode).map(val=>{
                            val.fields.map(sv=>{ 
                                if(b.fieldCodes.includes(sv.code)){
                                    selects.push(sv.code);
                                }
                            })
                        })
                        prizeCombinations.push({
                            id: b.id,
                            version: b.version,
                            linkCode: b.linkCode,
                            limitStatus: b.limitStatus ? b.limitStatus : [],
                            fieldCodes: selects, 
                            // fieldCodes: b.fieldCodes ? b.fieldCodes : [],
                            optionStatus: b.optionStatus,
                            fileType: b.fileType ? b.fileType : 'SINGLE',
                        })

                    }
                    else {
                        prizeCombinations.push({ linkCode: this.props.linksSelectionsList.filter(key => key.id == values[i])[0].code, limitStatus: [], fieldCodes: [] })

                    }
                }
                this.props.initialize({ prizeCombinations });
            }
            else {
                alert('请选择')
            }
        }
        else if (value == 2) {
            this.setState({ type: 1 })
        }
    }

    modalOk = (value) => {
        const parsed = queryString.parse(this.props.location.search);
        const { id } = parsed;
        this.props.createLink(
            {
                data: { list: value.prizeCombinations, id: id },
                callback: () => {
                    this.props.loadLinksList({
                        data: { id },
                        callback: () => {

                            setTimeout(() => {
                                if (this.props.LinksList.content.length > 0) {
                                    let a = this.props.LinksList.content.map(item => { return item.linkCode })
                                    let b = [];
                                    for (let i = 0; i < a.length; i++) {
                                        this.props.linksSelectionsList.filter(key => key.code == a[i])[0] ? b.push(this.props.linksSelectionsList.filter(key => key.code == a[i])[0].id) : '';

                                    }
                                    this.setState({ value: b, type: 1 })
                                    let prizeCombinations = [];
                                    // for (let i = 0; i < this.props.LinksList.content.length; i++) {
                                    //     let b = this.props.LinksList.content[i]
                                    //     prizeCombinations.push({
                                    //         id: b.id,
                                    //         version: b.version,
                                    //         linkCode: b.linkCode,
                                    //         limitStatus: b.limitStatus,
                                    //         fieldCodes: b.fieldCodes,
                                    //         optionStatus: b.optionStatus,
                                    //         fileType: b.fileType,
                                    //     })
                                    // }
                                    // this.props.initialize({ prizeCombinations });


                                }
                                else {
                                    this.setState({ type: 1 })
                                }
                            }, 1);

                        }
                    })

                    // this.props.handleCancel();

                    // this.props.loadSeqnumPasses(this.props.params2)
                    // this.props.loadSeqnumUnauditeds(this.props.params)
                    // this.props.changeSelections([]);
                }
            }
        )
    }

    render() {

        const parsed = queryString.parse(this.props.location.search);
        const { name } = parsed;
        return (


            <div className="allotDataCenter">
                <div className='title'>{name}</div>
                <div className="main-block">
                    <div style={{ marginBottom: 15 }}>
                        {this.state.type == 1 && <ListSelector
                            value={this.state.value}
                            list={this.props.linksSelectionsList}
                            onChange={(value) => {
                                this.DummyTransferChange(value)
                            }}
                        ></ListSelector>}

                        {this.state.type == 2 && <FieldArray name="prizeCombinations" component={table} list={this.props.linksSelectionsList} />}
                    </div>
                    <div className='ml20 mr20' style={{ paddingTop: 15, borderTop: '1px solid #ddd' }}>


                        {this.state.type == 2 && <Button className="tc-15-btn weak btn-add" onClick={(e) => this.goNext(2)}>上一步</Button>}
                        {this.state.type == 2 && <Button className="tc-15-btn" onClick={this.props.handleSubmit(this.modalOk)}>完成</Button>}
                        {this.state.type == 1 && <Button className="tc-15-btn" onClick={(e) => this.goNext(1)}>下一步</Button>}
                    </div>
                </div>

            </div>
        )
    }
}


const CodeTempConfig = reduxForm({
    form: 'awardZHForm',

})(CodeTempConfig0);


export default CodeTempConfig;